import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import { API_BASE_URL, consol } from "../../config";
import * as actions from '../../reducers/actions';
import { CAlert, CCol, CSpinner } from '@coreui/react';
import CourseCard from './courseCard';

const Login = (props) => {
    const { t } = useTranslation();
    const location = useLocation();
    const [submitted, setSubmitted] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(false);
    const [Banner, setBanner] = useState("assets/images/informatique.png");
    const [notActive, setNotActive] = useState(false);
    const [loggedInDashboard, setLoggedInDashboard] = useState(false);
    const [recentCourses, setRecentCourses] = useState([]);

    const validEmailRegex = RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    useEffect(() => {

        console.log('medical home')

        axios(`${API_BASE_URL}/trainings-main-medical`)
            .then((res) => res.data)
            .then(
                (result) => {
                    console.log('mmmmm', result)
                    setRecentCourses(result)

                },
                (error) => {
                    console.log(error);
                }
            );


    }, []);

    useEffect(() => {
        if (location.hash === '#login') {
            const loginSection = document.getElementById('login');
            if (loginSection) {
                loginSection.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location]);


    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmitted(true);
        if (validEmailRegex.test(email) && password) {
            login();
            /*     const formData = new FormData();
                 formData.append("email", email);
                 formData.append("password", password);
                 formData.append("medical", true);
                 axios
                 .post(`${API_BASE_URL}/login`, formData)
                 .then((res) => {
     
                     console.log(res.data)
                     if (res.data.user) {
                       localStorage.setItem('auth', res.data);
                       localStorage.setItem('username', res.data.user.email);
                       localStorage.setItem('roles', res.data.user.roles);
                       localStorage.setItem('id', res.data.user.id);
                       localStorage.setItem('medical', true);
                       console.log(localStorage.getItem('roles'))
                       props.history.push("/checkout");
                     } else {
                       setError(true);
                     }
                     
                 })
                 .catch((response) => {
     
                     console.log(response)
                 }); */


        }


    }

    const login = async () => {
        consol('logging in')
        try {
            const res = await axios.post(`${API_BASE_URL}/members/login`, { email, password }, { withCredentials: true });

            console.log('login resxxxx :', res);
            if (res.data.user) {
                //  Cookies.set('token', res.data.token, { secure: true, sameSite: 'None' });
                localStorage.setItem('auth', res.data.user);
                localStorage.setItem('username', res.data.user.email);
                localStorage.setItem('firstName', res.data.user.firstName);
                localStorage.setItem('lastName', res.data.user.lastName);
                localStorage.setItem('roles', res.data.user.roles);
                localStorage.setItem('medical', true);
                //localStorage.setItem('id', res.data.user.id);
                if (res.data.user.profile && res.data.user.profile.length > 1) localStorage.setItem('profile', res.data.user.profile);

                props.history.push("/GMTC-medical-center/my-profile");
            } else if (res.data.active == false) {
                setNotActive(true);
            } else {
                setError(true);
            }
            setSubmitted(false);
        } catch (error) {
            setError(true);
            console.log('catch:', error);
            setSubmitted(false);
        }
    };

    return (

        <>
            <div className="s_homehero s_homeheroMedical">
                <div className="w_homeherotext">
                    <div className="c_page"><h1 data-gsap-animate="true" className="h_xlarge">
                        {t("slogan_1_1")}
                        <br />{t("slogan_1_2")}</h1>
                        <p data-w-id="a17349a6-ebfb-ca74-ee37-2ec10ab66c8b"
                            className="p_heroparagraph cc-homeherop">{t("slogan_2")}</p>
                        <div className="w_scrollline">
                            <div className="d_centeredtextandline">
                                <div className="sp_scroll">{t("scroll")}</div>
                                <div className="m_scrollline">
                                    <div className="scrollline"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w_headerimages">
                    <div className="w-layout-grid grid-6">
                        <div className="w-layout-grid grid-8">
                            <div id="w-node-f9a4d6293485-3a368f7a" className="img_heroframe cc-f1">
                                <div className=""></div>
                                <a className="link-block-6 w-inline-block"></a>
                                <div className="w_vertprojecttitle">

                                </div>
                            </div>


                        </div>
                        <div className="w_imggroup">
                            <div className="w-layout-grid grid-7">
                                <div id="w-node-8226eb520370-3a368f7a" className="img_heroframe cc-f4">
                                    <div className=""></div>
                                    <a className="lb_imggrid w-inline-block"></a>
                                    <div className="w_vertprojecttitle">

                                    </div>
                                </div>
                                <div id="w-node-e630e9f9e3b4-3a368f7a" className="img_heroframe cc-f5">
                                    <div className=""></div>
                                    <a className="link-block-5 w-inline-block"></a>
                                    <div className="w_vertprojecttitle">
                                        <div className="m_projecttitle">

                                        </div>
                                    </div>
                                </div>
                                <div id="w-node-35e2ed03adf0-3a368f7a" className="img_heroframe cc-f8">
                                    <div className=""></div>
                                    <div className="w_vertprojecttitle">
                                        <div className="m_projecttitle">

                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="medicalPageMainWrapper" style={{ "margin": "5%" }}>
                <section className="presentation_page_medical presentation_page_medicalNarrow" >

                    <strong>{t("medical_presentation")}</strong>
                </section>
                <div className='coursesSection'>
                    <div className='sectionTitle'><p>{t("Recent_courses")}</p></div>
                    <div className="row itemsRow">
                        {recentCourses &&
                            recentCourses.map((formation) => {
                                return (
                                    <CCol md="3" xs="6" key={formation.id}>
                                        {<div className='cardsWraper'>

                                            <CourseCard
                                                title={
                                                    formation.title && formation.title
                                                }
                                                // image={
                                                //   formation.path
                                                // }
                                                path={formation.demo && formation.demo}
                                                image={formation.imageTrainings && formation.imageTrainings.length > 0 ? formation.imageTrainings[0].path : ''}

                                                url="/#"
                                                start={formation.createdAt}
                                                end={formation.end}
                                                rate={formation.rate}
                                                sessionsCount={formation.sessions.length}
                                                sessionDuration={formation.sessionDuration}
                                                medical={true}
                                                id={formation.id}
                                            />
                                            {/*                       
                                              <TrainingCard
                                path={formation.demo && formation.demo}
                                image={formation.imageTrainings && formation.imageTrainings.length>0? formation.imageTrainings[0].path : ''}
                                title={formation.title}
                                formateur={formation.createdBy ? 
                                formation.createdBy.firstName&&formation.createdBy.firstName+" "+formation.createdBy.lastName
                                :''}
                                createdAt={formation.createdAt}
                                updatedAt={formation.updatedAt}
                                sessions={formation.sessions}
                                id={formation.id}
                                language={'English'}
                                medical={false}
                                >

                                </TrainingCard> */}

                                        </div>
                                        }
                                    </CCol>
                                );
                            })}
                        {!recentCourses.length && (
                            <div className="loadingGif">
                                <img src="/assets/icons/loading-bar.gif" />
                            </div>
                        )}
                    </div>
                </div>
                <div className="em_custom_styles w-embed">
                    <div className="s_indv_port_text_sec" >

                        <div className="row medicalLogin" style={{ width: "100%" }}>
                            <div className="col-md-6" style={{ paddingLeft: "0px" }}>
                                <div style={{ paddingRight: "25px" }} >


                                    <div className="gi_rep w-dyn-item " style={{ width: "100%", marginLeft: "0px" }} id="login">

                                        <form onSubmit={handleSubmit} className="form-validate" style={{ width: "100%" }}>

                                            <h3>{t("login")}</h3>


                                            <div className="fsRowBody fsCell fsFieldCell fsFirst fsLast fsLabelVertical fsSpan100" >
                                                <label className="fsLabel fsRequiredLabel"
                                                    htmlFor="name">{t("email")}<span
                                                        className="fsRequiredMarker">*</span> </label>


                                                <input type="text" value={email} size="55"
                                                    name="name" className="fsField form-control" onChange={(e) => setEmail(e.target.value)}
                                                />
                                                {submitted && !validEmailRegex.test(email) && (
                                                    <span className="invalid-feedback error">
                                                        Invalid email
                                                    </span>
                                                )}

                                            </div>
                                            <div className="fsRowBody fsCell fsFieldCell fsFirst fsLast fsLabelVertical fsSpan100">
                                                <label className="fsLabel fsRequiredLabel"
                                                    htmlFor="password">{t("password")}<span
                                                        className="fsRequiredMarker">*</span> </label>


                                                {!submitted && <input type="password" size="55" value={password}
                                                    name="password" className="fsField form-control"
                                                    onChange={(e) => setPassword(e.target.value)}
                                                />}
                                                {submitted && <input type="password" size="55" value={password}
                                                    name="password" className="fsField form-control"
                                                    onChange={(e) => setPassword(e.target.value)}
                                                />}

                                                {submitted && !password && (
                                                    <span className="invalid-feedback error">
                                                        {t("required")}
                                                    </span>
                                                )}
                                            </div>
                                            <br />
                                            {error && (
                                                <CAlert color="danger" >
                                                    {t("incorrect_login")}
                                                </CAlert>
                                            )}
                                            {notActive && (
                                                <CAlert color="danger" >
                                                    {t("unconfirmed_login")}
                                                </CAlert>
                                            )}
                                            {loggedInDashboard && (
                                                <CAlert color="danger" >
                                                    {t("already_loggedin")}
                                                </CAlert>
                                            )}
                                            {submitted && error && (
                                                <CAlert color="danger" >
                                                    {t("incorrect_login")}
                                                </CAlert>
                                            )}
                                            {!submitted && <div id="fsSubmit3395640" className="fsSubmit fsPagination">
                                                <input id="fsSubmitButton3395640" className="btn_black w-button btn-block"
                                                    type="submit" value={t("login")} width="80%" />

                                            </div>}
                                            {submitted && <div id="fsSubmit3395640" className="fsSubmit fsPagination">
                                                <input disabled id="fsSubmitButton3395640" className="btn_black w-button btn-block"
                                                    type="submit" value={t("login")} width="80%" /> <CSpinner style={{ display: "block", margin: "auto", marginTop: "10px" }} color="default" size="sm" />

                                            </div>}
                                            <br />
                                            <a href="{{ path('forget_password_page') }}" style={{ textAlign: "center" }}>{t("forget_password")}</a>
                                            <Link to="/GMTC-medical-center/create-account" style={{ textAlign: "right", float: "right" }}>{t("creer_compte_btn")}</Link>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <strong>{t("medical_connect_text1")}</strong><br />
                                <ul>
                                    <li>
                                        <strong>{t("medical_connect_text2")}</strong> {t("medical_connect_text3")}
                                    </li>
                                    <li>
                                        <strong>{t("medical_connect_text4")}</strong> {t("medical_connect_text5")}
                                    </li>
                                    <li>
                                        <strong>{t("medical_connect_text6")}</strong>{t("medical_connect_text7")}
                                    </li>
                                    <li>
                                        <strong>{t("medical_connect_text8")}</strong> {t("medical_connect_text9")}
                                    </li>
                                    <li>
                                        <strong>{t("medical_connect_text10")}</strong> {t("medical_connect_text11")}
                                    </li>
                                    <li>
                                        <strong>{t("medical_connect_text12")}</strong> {t("medical_connect_text13")}
                                    </li>
                                </ul>
                                <p>{t("medical_connect_text14")}</p>
                            </div>
                        </div>

                    </div>

                </div></div>

        </>

    )
}
const mapStateToProps = (state) => {
    console.log(state)
    // setErrors(state.auth.error);
    return {
        // errorMessage:state.auth.error,
        //  authenticated:state.auth.authenticated,

    };
}

export default connect(mapStateToProps, actions)(Login)

